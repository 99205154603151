import React, { FC } from 'react'
import Image, { ImageProps } from 'next/image'

const StaticImage: FC<ImageProps> = ({ alt = '', ...props }) => {
    return (
        <Image
            alt={alt}
            {...props}
            loader={({ src }: { src: string }) => src}
            unoptimized
            quality={70}
        />
    )
}

export default StaticImage
