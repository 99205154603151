import * as Sentry from '@sentry/nextjs'
import { NextPageContext } from 'next'
import NextErrorComponent, { ErrorProps } from 'next/error'
import Link from 'next/link'
import React, { ReactElement } from 'react'
import Head from 'next/head'
import Image from '@/components/StaticImage'
import StandardLayout from '@/layouts/Standard'

interface AppErrorProps extends Pick<ErrorProps, 'title'> {
    err?: Error
    hasGetInitialPropsRun?: boolean
    statusCode?: number
}

const S1ErrorPage = ({ hasGetInitialPropsRun, err, statusCode }: AppErrorProps): ReactElement => {
    if (!hasGetInitialPropsRun && err instanceof Error) {
        // getInitialProps is not called in case of
        // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
        // err via _app.js so it can be captured
        Sentry.captureException(err)
        // Flushing is not required in this case as it only happens on the client
    }
    const output =
        statusCode === 403 ? 'Oops... Game over!' : 'Zu diesem Thema können wir nichts finden :('
    return (
        <>
            <Head>
                <title>
                    {`${statusCode || ''} Zu diesem Thema können wir nichts finden :(`.trim()}
                </title>
            </Head>
            <StandardLayout noBack2Home>
                <figure className="relative -mx-4 mb-6 w-screen overflow-hidden sm:mx-auto sm:my-6 sm:w-full sm:rounded-lg">
                    <Image
                        src={`/images/errors/${statusCode || 500}.jpg`}
                        width={1000}
                        height={372}
                        alt={`${statusCode}`}
                        className="w-screen rounded-lg"
                    />
                </figure>
                <h1 className="relative -mx-4 mb-6 w-screen overflow-hidden text-center text-42 font-bold sm:mx-auto sm:my-6 sm:w-full sm:rounded-lg">
                    {output}
                </h1>
                <Link legacyBehavior href="/">
                    <a>
                        <button className="relative -mx-4 mb-6 w-screen overflow-hidden rounded bg-black text-42 font-bold text-white hover:bg-orange sm:mx-auto sm:my-6 sm:w-full sm:rounded-lg">
                            ZUR HOMEPAGE
                        </button>
                    </a>
                </Link>
            </StandardLayout>
        </>
    )
}

S1ErrorPage.getInitialProps = async (ctx: NextPageContext) => {
    const { err, asPath } = ctx
    const errorInitialProps: AppErrorProps = await NextErrorComponent.getInitialProps(ctx)
    errorInitialProps.statusCode = ctx.res?.statusCode

    // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
    // getInitialProps has run
    errorInitialProps.hasGetInitialPropsRun = true

    // Running on the server, the response object (`res`) is available.
    //
    // Next.js will pass an err on the server if a page's data fetching methods
    // threw or returned a Promise that rejected
    //
    // Running on the client (browser), Next.js will provide an err if:
    //
    //  - a page's `getInitialProps` threw or returned a Promise that rejected
    //  - an exception was thrown somewhere in the React lifecycle (render,
    //    componentDidMount, etc) that was caught by Next.js's React Error
    //    Boundary. Read more about what types of exceptions are caught by Error
    //    Boundaries: https://reactjs.org/docs/error-boundaries.html

    if (err) {
        errorInitialProps.err = err
        Sentry.captureException(err)

        // Flushing before returning is necessary if deploying to Vercel, see
        // https://vercel.com/docs/platform/limits#streaming-responses
        await Sentry.flush(2000)

        return errorInitialProps
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry
    Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`))
    await Sentry.flush(2000)

    return errorInitialProps
}

export default S1ErrorPage
